import store from '../store';

export default {
    namespaced: true,
    state: {
        bvmodalshow: false,
        bvmodalhide: false,
        modalbuttontext: '',
        modaldeletefilename: false,
        modaleditfilename: false,
        modalheader: '',
        modalitems: {},
        modalremovefield: false,
        modalsetfilename: false
    },
    getters: {
        bvmodalshow(state) {
            return state.bvmodalshow;
        },
        bvmodalhide(state) {
            return state.bvmodalhide;
        },
        modalbuttontext(state) {
            return state.modalbuttontext;
        },
        modaldeletefilename(state) {
            return state.modaldeletefilename;
        },
        modaleditfilename(state) {
            return state.modaleditfilename;
        },
        modalheader(state) {
            return state.modalheader;
        },
        modalitems(state) {
            return state.modalitems;
        },
        modalremovefield(state) {
            return state.modalremovefield;
        },
        modalsetfilename(state) {
            return state.modalsetfilename;
        }
    },
    mutations: {
        SET_BVMODALSHOW(state, bvmodalshow) {
            state.bvmodalshow = bvmodalshow;
        },
        SET_BVMODALHIDE(state, bvmodalhide) {
            state.bvmodalhide = bvmodalhide;
        },
        SET_MODALBUTTONTEXT(state, modalbuttontext) {
            state.modalbuttontext = modalbuttontext;
        },
        SET_MODALDELETEFILENAME(state, modaldeletefilename) {
            state.modaldeletefilename = modaldeletefilename;
        },
        SET_MODALEDITFILENAME(state, modaleditfilename) {
            state.modaleditfilename = modaleditfilename;
        },
        SET_MODALHEADER(state, modalheader) {
            state.modalheader = modalheader;
        },
        SET_MODALITEMS(state, modalitems) {
            state.modalitems = modalitems;
        },
        SET_MODALREMOVEFIELD(state, modalremovefield) {
            state.modalremovefield = modalremovefield;
        },
        SET_MODALSETFILENAME(state, modalsetfilename) {
            state.modalsetfilename = modalsetfilename;
        },
    },
    actions: {
        clearModal({ commit }) {
            commit('SET_MODALHEADER', '');
            commit('SET_MODALITEMS', {});
            commit('SET_MODALBUTTONTEXT', '');
            commit('SET_MODALSETFILENAME', false);
            commit('SET_MODALEDITFILENAME', false);
            commit('SET_MODALDELETEFILENAME', false);
            commit('SET_MODALREMOVEFIELD', false);
            commit('SET_BVMODALHIDE', 'modal');
            commit('SET_BVMODALSHOW', false);
        },

        setFileName({ commit, rootGetters }) {
            if (!rootGetters['fillabledocsetup/documentisnamed'] && rootGetters['fillabledocsetup/documentisuploaded']) {
                commit('SET_MODALHEADER', 'Set Document Name and Description');
                commit('SET_MODALITEMS', [{
                    modallabel: 'Name (required, and for display only)',
                    modalid: 'modal-input-name',
                    modelvalue: ''
                }, {
                    modallabel: 'Description (required, max 150 chars)',
                    modalid: 'modal-input-description',
                    modelvalue: ''
                }, {
                    modallabel: 'Database Table Name (required)',
                    modalid: 'modal-input-tablename',
                    modelvalue: ''
                }, {
                    modallabel: 'Select Union (required)',
                    modalid: 'modal-select-union',
                    modelvalue: ''
                }]);
                commit('SET_MODALBUTTONTEXT', 'SET');
                commit('SET_MODALSETFILENAME', true);
                commit('SET_BVMODALHIDE', false);
                commit('SET_BVMODALSHOW', 'modal');
            }
        },

        editFileName({ commit, rootGetters }) {
            if (rootGetters['fillabledocsetup/documentisnamed'] && rootGetters['fillabledocsetup/documentisuploaded']) {
                commit('SET_MODALHEADER', 'Edit Document Name and Description');
                commit('SET_MODALITEMS', [{
                    modallabel: 'Name (required, and for display only)',
                    modalid: 'modal-input-name',
                    modelvalue: rootGetters['fillabledocsetup/documentname']
                }, {
                    modallabel: 'Description (required, max 150 chars)',
                    modalid: 'modal-input-description',
                    modelvalue: rootGetters['fillabledocsetup/documentdescription']
                }, {
                    modallabel: 'Database Table Name (required)',
                    modalid: 'modal-input-tablename',
                    modelvalue: rootGetters['fillabledocsetup/documenttablename']
                }, {
                    modallabel: 'Union Name (required)',
                    modalid: 'modal-select-union',
                    modelvalue: rootGetters['fillabledocsetup/documenttableprefix']
                }]);
                commit('SET_MODALBUTTONTEXT', 'UPDATE');
                commit('SET_MODALEDITFILENAME', true);
                commit('SET_BVMODALHIDE', false);
                commit('SET_BVMODALSHOW', 'modal');
            }
        },

        deleteFileName({ commit }, event) {
            let fileidstr = event.target.id;
            let fileid = fileidstr.slice(9);
            commit('SET_MODALHEADER', 'Confirm Document Delete');
            commit('SET_MODALITEMS', [{
                modallabel: 'Please type: "DELETE" then click DELETE button.',
                modalid: 'modal-delete-typed',
                modelvalue: ''
            }]);
            commit('SET_MODALBUTTONTEXT', 'DELETE');
            commit('SET_MODALDELETEFILENAME', true);
            store.commit('fillabledocsetup/SET_DOCUMENTIDTODELETE', fileid);
            commit('SET_BVMODALHIDE', false);
            commit('SET_BVMODALSHOW', 'modal');
        },

        removeField({ commit, rootGetters }) {
            if (rootGetters['fieldbuilder/showremovefield']) {
                commit('SET_MODALHEADER', 'Confirm Field Remove');
                commit('SET_MODALITEMS', [{
                    modallabel: 'Please type: "REMOVE" then click REMOVE button.',
                    modalid: 'modal-remove-typed',
                    modelvalue: ''
                }]);
                commit('SET_MODALBUTTONTEXT', 'REMOVE');
                commit('SET_MODALREMOVEFIELD', true);
                store.commit('fieldbuilder/SET_FIELDIDTOREMOVE', rootGetters['fieldbuilder/currentfieldid']);
                commit('SET_BVMODALHIDE', false);
                commit('SET_BVMODALSHOW', 'modal');
            }
        }
    }
};