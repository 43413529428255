import axios from 'axios';
import store from '../store';
import { removeFieldListeners } from '../library/FieldListeners.js';

export default {
    namespaced: true,
    state: {
        currentfieldid: '',
        datatypeselected: '',
        fieldcharlimit: 255,
        fielddatabasename: '',
        fielddescription: '',
        fieldidtoremove: null,
        fieldisrequired: false,
        fieldlabeltext: '',
        fieldmoving: false,
        fieldplaceholder: '',
        fieldresizing: false,
        fields: null,
        fontsize: 12,
        mouseisdown: false,
        newfield: null,
        placeholderwillbeshown: false,
        restorefactor: null,
        showfieldbuilder: false,
        tooltipwillbeshown: false,

        //buttons
        showaddfield: false,
        showsavefield: false,
        showupdatefield: false,
        showremovefield: false,
        showclearfield: true
    },
    getters: {
        currentfieldid(state) {
            return state.currentfieldid;
        },
        datatypeselected(state) {
            return state.datatypeselected;
        },
        fieldplaceholder(state) {
            return state.fieldplaceholder;
        },
        fields(state) {
            return state.fields;
        },
        fieldcharlimit(state) {
            return state.fieldcharlimit;
        },
        fielddescription(state) {
            return state.fielddescription;
        },
        fielddatabasename(state) {
            return state.fielddatabasename;
        },
        fieldidtoremove(state) {
            return state.fieldidtoremove;
        },
        fieldisrequired(state) {
            return state.fieldisrequired;
        },
        fieldlabeltext(state) {
            return state.fieldlabeltext;
        },
        fieldmoving(state) {
            return state.fieldmoving;
        },
        fieldresizing(state) {
            return state.fieldresizing;
        },
        fontsize(state) {
            return state.fontsize;
        },
        placeholderwillbeshown(state) {
            return state.placeholderwillbeshown;
        },
        mouseidown(state) {
            return state.mouseidown;
        },
        newfield(state) {
            return state.newfield;
        },
        restorefactor(state) {
            return state.restorefactor;
        },
        showaddfield(state) {
            return state.showaddfield;
        },
        showclearfield(state) {
            return state.showclearfield;
        },
        showfieldbuilder(state) {
            return state.showfieldbuilder;
        },
        showsavefield(state) {
            return state.showsavefield;
        },
        showremovefield(state) {
            return state.showremovefield;
        },
        showupdatefield(state) {
            return state.showupdatefield;
        },
        tooltipwillbeshown(state) {
            return state.tooltipwillbeshown;
        },
    },
    mutations: {
        SET_CURRENTFIELDID(state, currentfieldid) {
            state.currentfieldid = currentfieldid;
        },
        SET_DATATYPESELECTED(state, datatypeselected) {
            state.datatypeselected = datatypeselected;
        },
        SET_SHOWFIELDBUILDER(state, showfieldbuilder) {
            state.showfieldbuilder = showfieldbuilder;
        },
        SET_FIELDCHARLIMIT(state, fieldcharlimit) {
            state.fieldcharlimit = fieldcharlimit;
        },
        SET_FIELDDATABASENAME(state, fielddatabasename) {
            state.fielddatabasename = fielddatabasename;
        },
        SET_FIELDDESCRIPTION(state, fielddescription) {
            state.fielddescription = fielddescription;
        },
        SET_FIELDIDTOREMOVE(state, fieldidtoremove) {
            state.fieldidtoremove = fieldidtoremove;
        },
        SET_FIELDISREQUIRED(state, fieldisrequired) {
            state.fieldisrequired = fieldisrequired;
        },
        SET_FIELDLABELTEXT(state, fieldlabeltext) {
            state.fieldlabeltext = fieldlabeltext;
        },
        SET_FIELDMOVING(state, fieldmoving) {
            state.fieldmoving = fieldmoving;
        },
        SET_FIELDPLACEHOLDER(state, fieldplaceholder) {
            state.fieldplaceholder = fieldplaceholder;
        },
        SET_FIELDRESIZING(state, fieldresizing) {
            state.fieldresizing = fieldresizing;
        },
        SET_FIELDS(state, fields) {
            state.fields = fields;
        },
        SET_FONTSIZE(state, fontsize) {
            state.fontsize = fontsize;
        },
        SET_MOUSEISDOWN(state, mouseisdown) {
            state.mouseisdown = mouseisdown;
        },
        SET_NEWFIELD(state, newfield) {
            state.newfield = newfield;
        },
        SET_PLACEHOLDERWILLBESHOWN(state, placeholderwillbeshown) {
            state.placeholderwillbeshown = placeholderwillbeshown;
        },
        SET_RESTOREFACTOR(state, restorefactor) {
            state.restorefactor = restorefactor;
        },
        SET_SHOWADDFIELD(state, showaddfield) {
            state.showaddfield = showaddfield;
        },
        SET_SHOWCLEARFIELD(state, showclearfield) {
            state.showclearfield = showclearfield;
        },
        SET_SHOWREMOVEFIELD(state, showremovefield) {
            state.showremovefield = showremovefield;
        },
        SET_SHOWSAVEFIELD(state, showsavefield) {
            state.showsavefield = showsavefield;
        },
        SET_SHOWUPDATEFIELD(state, showupdatefield) {
            state.showupdatefield = showupdatefield;
        },
        SET_TOOLTIPWILLBESHOWN(state, tooltipwillbeshown) {
            state.tooltipwillbeshown = tooltipwillbeshown;
        },

    },
    actions: {
        async saveFieldData({ commit, state, dispatch, rootGetters }) {
            if (state.showsavefield) {
                // convert data to actual proportions using actual document dimensions, and 
                // screen dimensions which display as full-width
                let resizeFactor = rootGetters['fillabledocsetup/documentwidth'] /
                    document.getElementById('field-overlay').offsetWidth;
                let el = document.getElementById('field-' + state.currentfieldid);

                let arr = {
                    document_id: rootGetters['fillabledocsetup/documentfileid'],
                    required: state.fieldisrequired,
                    font_size: state.fontsize * resizeFactor,
                    label_text: state.fieldlabeltext,
                    placeholder: state.fieldplaceholder,
                    placeholdershow: state.placeholderwillbeshown,
                    description: state.fielddescription,
                    descriptionshow: state.tooltipwillbeshown,
                    location_x: el.offsetLeft * resizeFactor,
                    location_y: el.offsetTop * resizeFactor,
                    width: el.offsetWidth * resizeFactor,
                    height: el.offsetHeight * resizeFactor,
                    type: state.datatypeselected,
                    max_chars: state.fieldcharlimit,
                    data_name: state.fielddatabasename
                };
                // console.log(arr);
                store.dispatch('loadingmsg/turnOnLoadingMsg');
                let response = await axios.post('docs/savefielddata', arr);

                if (response && response.data.errormsg) {
                    store.dispatch('loadingmsg/turnOffLoadingMsg');
                    store.dispatch('notification/processErrorMessage', response.data.errormsg);

                } else if (response && response.data.message) {
                    store.dispatch('loadingmsg/turnOffLoadingMsg');
                    store.dispatch('notification/processSuccessMessage', response.data.message);
                    dispatch('clearFieldAndReset', false);
                    commit('SET_FIELDS', response.data.field_array);
                }
            }

        },

        async updateFieldData({ commit, state, dispatch, rootGetters }) {
            if (state.showupdatefield) {
                // convert data to actual proportions using actual document dimensions, and 
                // screen dimensions which display as full-width
                let resizeFactor = rootGetters['fillabledocsetup/documentwidth'] /
                    document.getElementById('document-data').offsetWidth;
                let el = document.getElementById('field-' + state.currentfieldid);

                let arr = {
                    document_id: rootGetters['fillabledocsetup/documentfileid'],
                    fieldid: state.currentfieldid,
                    required: state.fieldisrequired,
                    label_text: state.fieldlabeltext,
                    font_size: state.fontsize * resizeFactor,
                    placeholder: state.fieldplaceholder,
                    placeholdershow: state.placeholderwillbeshown,
                    description: state.fielddescription,
                    descriptionshow: state.tooltipwillbeshown,
                    location_x: el.offsetLeft * resizeFactor,
                    location_y: el.offsetTop * resizeFactor,
                    width: el.offsetWidth * resizeFactor,
                    height: el.offsetHeight * resizeFactor,
                    type: state.datatypeselected,
                    max_chars: state.fieldcharlimit,
                    data_name: state.fielddatabasename
                };
                // console.log(arr);
                store.dispatch('loadingmsg/turnOnLoadingMsg');
                let response = await axios.post('docs/savefielddata', arr);

                if (response && response.data.errormsg) {
                    store.dispatch('loadingmsg/turnOffLoadingMsg');
                    store.dispatch('notification/processErrorMessage', response.data.errormsg);
                } else if (response && response.data.message) {
                    store.dispatch('loadingmsg/turnOffLoadingMsg');
                    store.dispatch('notification/processSuccessMessage', response.data.message);
                    dispatch('clearFieldAndReset', false);
                    commit('SET_FIELDS', response.data.field_array);
                }
            }

        },

        async removeFieldData({ commit, state, dispatch, rootGetters }) {
            store.dispatch('loadingmsg/turnOnLoadingMsg');
            let response = await axios.post('docs/removefielddata', {
                fieldid: state.fieldidtoremove,
                document_id: rootGetters['fillabledocsetup/documentfileid']
            });

            if (response && response.data.errormsg) {
                store.dispatch('loadingmsg/turnOffLoadingMsg');
                store.dispatch('notification/processErrorMessage', response.data.errormsg);
                return false;
            } else if (response && response.data.message) {
                store.dispatch('loadingmsg/turnOffLoadingMsg');
                store.dispatch('notification/processSuccessMessage', response.data.message);
                commit('SET_FIELDIDTOREMOVE', null);
                dispatch('clearFieldAndReset', false);
                commit('SET_FIELDS', response.data.field_array);
                return true;
            }
        },

        async getFieldArray({ commit, rootGetters }) {
            let response = await axios.post('docs/getfieldarray', {
                document_id: rootGetters['fillabledocsetup/documentfileid']
            });

            if (response && response.data.errormsg) {
                store.dispatch('notification/processErrorMessage', response.data.errormsg);
                return false;
            } else if (response && response.data.field_array) {
                // console.log(response.data.field_array);
                commit('SET_FIELDS', response.data.field_array);
                return true;
            }
        },

        clearFieldAndReset({ commit, state, dispatch }, noaction) {
            // console.log('Clearing field options.');
            if (state.currentfieldid == 100) {
                if (document.getElementById('field-' + state.currentfieldid) != null) {
                    document.getElementById('field-' + state.currentfieldid).remove();
                }
                commit('SET_NEWFIELD', null);
                dispatch('resetOptions');
            } else {
                if (noaction) {
                    dispatch('getOriginalPlaceholder', state.currentfieldid).then((ph) => {
                        // console.log('currenfieldid: ' + state.currentfieldid);
                        // console.log('ph: ' + ph);
                        if (document.getElementById('field-' + state.currentfieldid + '-input')) {
                            document.getElementById('field-' + state.currentfieldid + '-input').placeholder = (ph) ? ph : "";
                        }
                    });
                    dispatch('resetOptions');
                } else {
                    // console.log('currenfieldid: ' + state.currentfieldid);
                    if (document.getElementById('field-' + state.currentfieldid + '-input')) {
                        document.getElementById('field-' + state.currentfieldid + '-input').placeholder = (state.fieldplaceholder) ? state.fieldplaceholder : "";
                    }
                    dispatch('resetOptions');
                }
            }
        },

        resetOptions({ commit, state }) {
            removeFieldListeners(state.currentfieldid);
            commit('SET_FIELDISREQUIRED', false);
            commit('SET_FIELDLABELTEXT', '');
            commit('SET_DATATYPESELECTED', '');
            commit('SET_FIELDPLACEHOLDER', '');
            commit('SET_PLACEHOLDERWILLBESHOWN', false);
            commit('SET_FIELDCHARLIMIT', 255);
            commit('SET_FIELDDESCRIPTION', '');
            commit('SET_TOOLTIPWILLBESHOWN', false);
            commit('SET_FIELDDATABASENAME', '');
            commit('SET_CURRENTFIELDID', '');
            commit('SET_SHOWADDFIELD', false);
            commit('SET_SHOWSAVEFIELD', false);
            commit('SET_SHOWUPDATEFIELD', false);
            commit('SET_SHOWREMOVEFIELD', false);
            return;
        },

        makeFieldActive({ commit, state, rootGetters }, fieldid) {
            if (state.currentfieldid != fieldid) {
                if (!rootGetters['fieldorder/showfieldordersave']) {
                    // console.log('setting current field id: ' + fieldid);
                    commit('SET_CURRENTFIELDID', fieldid);
                    commit('SET_SHOWSAVEFIELD', false);
                    commit('SET_SHOWUPDATEFIELD', true);
                    if (document.getElementById('panel-a').classList.contains('hide')) {
                        store.dispatch('fillabledocsetup/toggleTabs');
                    }
                } else if (rootGetters['fieldorder/showfieldordersave']) {
                    store.dispatch('notification/processErrorMessage', 'Save/Update/Clear field options first.');
                }
            }
        },

        async getOriginalPlaceholder({ state }, fieldid) {
            return new Promise(resolve => {
                if (state.fields != 'undefined') {
                    if (state.fields.length > 0) {
                        state.fields.forEach(field => {
                            if (field.id === fieldid) {
                                // console.log(field.placeholder);
                                resolve(field.placeholder);
                            }
                        });
                    } else {
                        resolve();
                    }
                } else {
                    resolve();
                }

            });
        },

        populateFieldSettings({ commit, state }) {
            let fieldindex = state.fields.findIndex(o => o.id === state.currentfieldid);
            // console.log(fieldindex);
            let field = state.fields[fieldindex];
            commit('SET_FIELDISREQUIRED', field.required);
            commit('SET_FIELDLABELTEXT', field.label_text);
            commit('SET_FIELDPLACEHOLDER', field.placeholder);
            commit('SET_PLACEHOLDERWILLBESHOWN', field.placeholdershow);
            commit('SET_DATATYPESELECTED', field.type);
            commit('SET_FIELDCHARLIMIT', field.max_chars);
            commit('SET_FIELDDESCRIPTION', field.description);
            commit('SET_TOOLTIPWILLBESHOWN', field.descriptionshow);
            commit('SET_FIELDDATABASENAME', field.data_name);
            commit('SET_FONTSIZE', Math.round(state.restorefactor * field.font_size));
            commit('SET_SHOWADDFIELD', false);
            commit('SET_SHOWSAVEFIELD', true);
            commit('SET_SHOWREMOVEFIELD', true);
            commit('SET_SHOWCLEARFIELD', true);
        },

        setNewField({ commit }, arr) {
            commit('SET_NEWFIELD', arr);
        },

    }
};