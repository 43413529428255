export default {
    namespaced: true,
    state: {
        pageerror: null,
        pagemessage: null
    },
    getters: {
        pageerror(state) {
            return state.pageerror;
        },
        pagemessage(state) {
            return state.pagemessage;
        },
    },
    mutations: {
        SET_PAGEERROR(state, pageerror) {
            state.pageerror = pageerror;
        },
        SET_PAGEMESSAGE(state, pagemessage) {
            state.pagemessage = pagemessage;
        },
    },
    actions: {}
};