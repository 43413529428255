<template>
<div id="app">
    <div v-cloak>
        <router-view />
    </div>
    <LoadingMsg />
</div>
</template>

<script>
const LoadingMsg = () => import( /* webpackChunkName: "LoadingMsg" */ './components/LoadingMsg');
import { mapGetters } from 'vuex';

export default {
    components: {
        LoadingMsg
    },

    computed: {
        ...mapGetters({
            loadingmsg: 'loadingmsg/loadingmsg',
        }),

    },
};
</script>
<style>
html {
    width: 100%;
    /* background-color: #ededed; */
}

#app {
    min-height: 100%;
    max-height: 100%;
}

[v-cloak] {
    display: none !important;
}
</style>
