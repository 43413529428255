import axios from 'axios';
import store from '../store';

export default {
    namespaced: true,
    state: {
        // selected file refers to uploaded file on client side
        selectedfile: null, // file object
        selectedfilename: '', // actual file name after upload
        selectedfilesource: null, // converted to URL for display
        showselectedfile: false
    },
    getters: {
        selectedfile(state) {
            return state.selectedfile;
        },
        selectedfilename(state) {
            return state.selectedfilename;
        },
        selectedfilesource(state) {
            return state.selectedfilesource;
        },
        showselectedfile(state) {
            return state.showselectedfile;
        }
    },
    mutations: {
        SET_SELECTEDFILE(state, selectedfile) {
            state.selectedfile = selectedfile;
        },
        SET_SELECTEDFILENAME(state, selectedfilename) {
            state.selectedfilename = selectedfilename;
        },
        SET_SELECTEDFILESOURCE(state, selectedfilesource) {
            state.selectedfilesource = selectedfilesource;
        },
        SET_SHOWSELECTEDFILE(state, showselectedfile) {
            state.showselectedfile = showselectedfile;
        }
    },
    actions: {
        openUploadFile({ commit, rootGetters }) {
            if (!rootGetters['fillabledocsetup/documentisuploaded']) {
                document.getElementById('file-hidden-input').click();
                commit('SET_SHOWSELECTEDFILE', false);
                commit('SET_SELECTEDFILESOURCE', null);
            }
        },

        selectedfileClearAndReset({ commit }) {
            commit('SET_SELECTEDFILE', null);
            commit('SET_SELECTEDFILESOURCE', null);
            commit('SET_SHOWSELECTEDFILE', false);
            commit('SET_SELECTEDFILENAME', '');
        },

        fileSelected({ commit }, file) {
            if (!file) {
                store.dispatch('notification/processErrorMessage', 'There\'s no file!');
                // console.log('no file');
                return;
            }

            if (file.type !== 'image/png') {
                store.dispatch('notification/processErrorMessage', 'Only PNG images are allowed.');
                // console.log('wrong file type');
                return;

            }

            commit('SET_SELECTEDFILE', file);
            commit('SET_SELECTEDFILESOURCE', URL.createObjectURL(file));
            commit('SET_SHOWSELECTEDFILE', true);
            let ms = new Date().valueOf();
            commit('SET_SELECTEDFILENAME', 'fd_' + ms + '.png');
            store.commit('fillabledocsetup/SET_DOCUMENTISUPLOADED', true);
        },

        async commitFile({ commit, state, rootGetters }) {
            if (rootGetters['fillabledocsetup/documentisnamed'] &&
                rootGetters['fillabledocsetup/documentisuploaded'] &&
                rootGetters['fillabledocsetup/documentischanged']) {
                store.dispatch('loadingmsg/turnOnLoadingMsg');
                if (state.selectedfile) {
                    // new file being saved
                    let formData = new FormData();
                    let dfi = rootGetters['fillabledocsetup/documentfileid'];
                    if (dfi) {
                        formData.append('file_id', dfi);
                    }
                    formData.append('file', state.selectedfile);
                    formData.append('file_name', state.selectedfilename);
                    formData.append('file_display_name', rootGetters['fillabledocsetup/documentname']);
                    formData.append('file_description', rootGetters['fillabledocsetup/documentdescription']);
                    formData.append('file_table_name', rootGetters['fillabledocsetup/documenttablename']);
                    formData.append('file_table_prefix', rootGetters['fillabledocsetup/documenttableprefix']);
                    let response = await axios.post('docs/fillableupload',
                        formData, {
                            headers: {
                                'Content-Type': 'multipart/form-data'
                            }
                        }
                    );
                    if (response && response.data.errormsg) {
                        console.log(response.data.errormsg);
                        store.dispatch('notification/processErrorMessage', response.data.errormsg);
                        store.dispatch('loadingmsg/turnOffLoadingMsg');
                        return false;
                    } else if (response && response.data.document_id) {
                        commit('SET_SELECTEDFILE', null);
                        commit('SET_SELECTEDFILENAME', '');
                        commit('SET_SELECTEDFILESOURCE', null);
                        commit('SET_SHOWSELECTEDFILE', false);

                        // console.log(response.data.file);
                        store.dispatch('notification/processSuccessMessage', 'Successfully saved.');
                        store.commit('fillabledocsetup/SET_DOCUMENTFILEID', response.data.document_id);
                        store.commit('fillabledocsetup/SET_DOCUMENTISCHANGED', false);
                        return true;
                    }

                } else {
                    // existing file being updated
                    let response = await axios.post('docs/fillableupdate', {
                        file_id: rootGetters['fillabledocsetup/documentfileid'],
                        file_display_name: rootGetters['fillabledocsetup/documentname'],
                        file_description: rootGetters['fillabledocsetup/documentdescription'],
                        file_table_name: rootGetters['fillabledocsetup/documenttablename'],
                        file_table_prefix: rootGetters['fillabledocsetup/documenttableprefix']

                    });
                    if (response && response.data.errormsg) {
                        // console.log(response.data.errormsg);
                        store.dispatch('loadingmsg/turnOffLoadingMsg');
                        store.dispatch('notification/processErrorMessage', response.data.errormsg);
                        return false;
                    } else if (response && response.data.message) {
                        // console.log(response.data.message);
                        store.dispatch('loadingmsg/turnOffLoadingMsg');
                        store.dispatch('notification/processSuccessMessage', response.data.message);
                        store.commit('fillabledocsetup/SET_DOCUMENTISCHANGED', false);
                        return true;
                    }
                }
            }
        }
    }
};