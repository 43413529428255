import store from '@/store';
import axios from 'axios';

store.subscribe((mutation) => {
    switch (mutation.type) {
        case 'auth/SET_TOKEN':
            if (mutation.payload != null) {
                // console.log('setting committed token to Bearer header');
                axios.defaults.headers.common.Authorization = `Bearer ${mutation.payload}`;
                localStorage.setItem('token', mutation.payload);
            } else {
                axios.defaults.headers.common.Authorization = null;
                localStorage.removeItem('token');
            }
            break;

        case 'anonauth/SET_ANONTOKEN':
            if (mutation.payload != null) {
                axios.defaults.headers.common.Authorization = `Bearer ${mutation.payload}`;
                localStorage.setItem('anontoken', mutation.payload);
            } else {
                axios.defaults.headers.common.Authorization = null;
                localStorage.removeItem('anontoken');
            }
            break;

    }
});