import axios from 'axios';
import store from '../store';

export default {
    namespaced: true,
    state: {
        documentdata: null,
        documentdescription: '',
        documentfileid: null,
        documentheight: '',
        documentidtodelete: '',
        documentischanged: false,
        documentisnamed: false,
        documentisuploaded: false,
        documentlist: '',
        documentname: '', // display name
        documenttablename: '',
        documenttableprefix: '',
        documentunion: '',
        documentwidth: '',
        showdocument: false,
        unions: [],
        unionsfound: false
    },
    getters: {
        documentdata(state) {
            return state.documentdata;
        },
        documentdescription(state) {
            return state.documentdescription;
        },
        documentfileid(state) {
            return state.documentfileid;
        },
        documentheight(state) {
            return state.documentheight;
        },
        documentidtodelete(state) {
            return state.documentidtodelete;
        },
        documentischanged(state) {
            return state.documentischanged;
        },
        documentisnamed(state) {
            return state.documentisnamed;
        },
        documentisuploaded(state) {
            return state.documentisuploaded;
        },
        documentlist(state) {
            return state.documentlist;
        },
        documentname(state) {
            return state.documentname;
        },
        documenttablename(state) {
            return state.documenttablename;
        },
        documenttableprefix(state) {
            return state.documenttableprefix;
        },
        documentunion(state) {
            return state.documentunion;
        },
        documentwidth(state) {
            return state.documentwidth;
        },
        showdocument(state) {
            return state.showdocument;
        },
        unions(state) {
            return state.unions;
        },
        unionsfound(state) {
            return state.unionsfound;
        }
    },
    mutations: {
        SET_DOCUMENTDATA(state, documentdata) {
            state.documentdata = documentdata;
        },
        SET_DOCUMENTDESCRIPTION(state, documentdescription) {
            state.documentdescription = documentdescription;
        },
        SET_DOCUMENTFILEID(state, documentfileid) {
            state.documentfileid = documentfileid;
        },
        SET_DOCUMENTHEIGHT(state, documentheight) {
            state.documentheight = documentheight;
        },
        SET_DOCUMENTIDTODELETE(state, documentidtodelete) {
            state.documentidtodelete = documentidtodelete;
        },
        SET_DOCUMENTISCHANGED(state, documentischanged) {
            state.documentischanged = documentischanged;
        },
        SET_DOCUMENTISNAMED(state, documentisnamed) {
            state.documentisnamed = documentisnamed;
        },
        SET_DOCUMENTISUPLOADED(state, documentisuploaded) {
            state.documentisuploaded = documentisuploaded;
        },
        SET_DOCUMENTLIST(state, documentlist) {
            state.documentlist = documentlist;
        },
        SET_DOCUMENTNAME(state, documentname) {
            state.documentname = documentname;
        },
        SET_DOCUMENTTABLENAME(state, documenttablename) {
            state.documenttablename = documenttablename;
        },
        SET_DOCUMENTTABLEPREFIX(state, documenttableprefix) {
            state.documenttableprefix = documenttableprefix;
        },
        SET_DOCUMENTUNION(state, documentunion) {
            state.documentunion = documentunion;
        },
        SET_DOCUMENTWIDTH(state, documentwidth) {
            state.documentwidth = documentwidth;
        },
        SET_SHOWDOCUMENT(state, showdocument) {
            state.showdocument = showdocument;
        },
        SET_UNIONS(state, unions) {
            state.unions = unions;
        },
        SET_UNIONSFOUND(state, unionsfound) {
            state.unionsfound = unionsfound;
        }
    },
    actions: {
        async openDocumentList({
            commit,
            state
            // , dispatch 
        }) {
            if (!state.documentisuploaded) {
                store.dispatch('loadingmsg/turnOnLoadingMsg');
                let response = await axios.post('docs/fillablelist');
                if (response.data.errormsg) {
                    // console.log(response.data.errormsg);
                    store.dispatch('loadingmsg/turnOffLoadingMsg');

                    store.dispatch('notification/processErrorMessage', response.data.errormsg);
                } else if (response.data.list) {
                    // console.log(response.data.list);
                    // let dl = [];
                    // response.data.list.forEach(document => {
                    //     document.union = dispatch('getUnionText', document.union);
                    // });
                    store.dispatch('loadingmsg/turnOffLoadingMsg');
                    commit('SET_DOCUMENTLIST', response.data.list);
                    store.commit('fillabledocmodal/SET_BVMODALHIDE', false);
                    store.commit('fillabledocmodal/SET_BVMODALSHOW', 'documentlist-modal');
                }
            }
        },

        getUnionText({ state }, unionvalue) {
            // console.log('unions: ' + state.unions);
            let idx = state.unions.findIndex(key => (key.value === unionvalue));
            return state.unions[idx].text;
        },

        async openDocumentSelected({ commit, state, dispatch }) {
            let response = await axios.post('docs/fillablefetch', { file_id: state.documentfileid });
            if (response.data.errormsg) {
                // console.log(response.data.errormsg);
                store.dispatch('loadingmsg/turnOffLoadingMsg');
                store.dispatch('notification/processErrorMessage', response.data.errormsg);
                return false;
            }
            if (response.data.document) {
                let d = response.data.document;
                commit('SET_DOCUMENTFILEID', d.document_file_id);
                commit('SET_DOCUMENTNAME', d.document_name);
                commit('SET_DOCUMENTDESCRIPTION', d.document_description);
                commit('SET_DOCUMENTTABLENAME', d.document_table_name);
                commit('SET_DOCUMENTTABLEPREFIX', d.document_table_prefix);
                dispatch('getUnionText', d.document_table_prefix).then((union) => {
                    // console.log('union: ' + union);
                    commit('SET_DOCUMENTUNION', union);
                });
                commit('SET_DOCUMENTWIDTH', d.width);
                commit('SET_DOCUMENTHEIGHT', d.height);
                commit('SET_SHOWDOCUMENT', true);
                commit('SET_DOCUMENTDATA', d.document);
                commit('SET_DOCUMENTISUPLOADED', true);
                commit('SET_DOCUMENTISNAMED', true);
                if (store.state.fillabledocmodal.bvmodalshow) {
                    store.commit('fillabledocmodal/SET_BVMODALHIDE', 'documentlist-modal');
                    store.commit('fillabledocmodal/SET_BVMODALSHOW', false);
                }
                store.commit('fieldbuilder/SET_SHOWFIELDBUILDER', true);
                store.commit('fieldbuilder/SET_FIELDS', d.fields);
                return true;
            }
        },

        async deleteDocumentSelected({ state }) {
            store.dispatch('loadingmsg/turnOnLoadingMsg');
            let response = await axios.post('docs/fillabledelete', { file_id: state.documentidtodelete });
            if (response.data.message) {
                // console.log(response.data.message);
                store.dispatch('fillabledocmodal/clearModal');
                store.commit('fillabledocmodal/SET_MODALDELETEFILENAME', false);
                store.commit('fillabledocmodal/SET_BVMODALHIDE', 'documentlist-modal');
                store.commit('fillabledocmodal/SET_BVMODALSHOW', false);
                store.dispatch('loadingmsg/turnOffLoadingMsg');
                store.dispatch('notification/processSuccessMessage', response.data.message);

            } else if (response.data.errormsg) {
                // console.log(response.data.errormsg);
                store.dispatch('fillabledocmodal/clearModal');
                store.dispatch('loadingmsg/turnOffLoadingMsg');
                store.dispatch('notification/processErrorMessage', response.data.errormsg);
            }
        },

        fillabledocClearAndReset({ commit }) {
            commit('SET_DOCUMENTHEIGHT', '');
            commit('SET_DOCUMENTWIDTH', '');
            commit('SET_DOCUMENTFILEID', null);
            commit('SET_DOCUMENTLIST', '');
            commit('SET_DOCUMENTNAME', '');
            commit('SET_DOCUMENTDESCRIPTION', '');
            commit('SET_DOCUMENTTABLENAME', '');
            commit('SET_DOCUMENTTABLEPREFIX', '');
            commit('SET_DOCUMENTISUPLOADED', false);
            commit('SET_DOCUMENTISNAMED', false);
            commit('SET_DOCUMENTISCHANGED', false);
            commit('SET_DOCUMENTDATA', null);
            commit('SET_SHOWDOCUMENT', false);
            store.commit('fieldbuilder/SET_RESTOREFACTOR', null);
            store.dispatch('fieldbuilder/clearFieldAndReset');
        },

        toggleTabs({ rootGetters }) {
            // console.log('Toggling tabs');
            if (document.getElementById('panel-b').classList.contains('hide') &&
                (rootGetters['fieldbuilder/showsavefield'] || rootGetters['fieldbuilder/showupdatefield'])) {
                store.dispatch('notification/processErrorMessage', 'Save/Update/Clear field options first.');

            } else if (document.getElementById('panel-a').classList.contains('hide') &&
                rootGetters['fieldorder/showfieldordersave']) {
                store.dispatch('notification/processErrorMessage', 'Commit/Clear field order first.');
            } else {
                document.getElementById('panel-a').classList.toggle('hide');
                document.getElementById('panel-b').classList.toggle('show');
                document.getElementById('panel-b').classList.toggle('hide');
            }
        },

        async getUnions({ commit }) {
            let response = await axios.get('docs/getunions');
            if (response.data.unions) {
                // console.log(response.data.unions);
                let u = response.data.unions;
                let unionoptions = [];
                Object.keys(u).forEach(function(key) {
                    unionoptions.push({ value: u[key], text: key });
                });
                commit('SET_UNIONS', unionoptions);
                commit('SET_UNIONSFOUND', true);
                store.dispatch('loadingmsg/turnOffLoadingMsg');
            }
        }
    }
};