import axios from 'axios';
export default {
    namespaced: true,
    state: {
        error: null,
        successmsg: null,
        token: null,
        user: ''
    },
    getters: {
        authenticated(state) {
            return state.token && state.user;
        },
        error(state) {
            return state.error;
        },
        successmsg(state) {
            return state.successmsg;
        },
        token(state) {
            return state.token;
        },
        user(state) {
            return state.user;
        }
    },
    mutations: {
        // changes state items (current state, any data passed)
        SET_ERROR(state, error) {
            state.error = error;
        },
        SET_SUCCESSMSG(state, successmsg) {
            state.successmsg = successmsg;
        },
        SET_TOKEN(state, token) {
            state.token = token;
        },
        SET_USER(state, user) {
            state.user = user;
        }
    },
    actions: {
        async signIn({ commit }, credentials) {
            let response = await axios.post('auth/signin', credentials);
            if (response && response.data.autherrormsg) {
                commit('SET_ERROR', response.data.autherrormsg);
                return false;
            }
            if (response) {
                commit('SET_ERROR', null);
                commit('SET_USER', response.data.user);
                commit('SET_TOKEN', response.data.access_token);
                return true;
            } else {
                //
            }
        },
        async attempt({ commit }) {
            // headers with token set in subscriber.js
            let response = await axios.get('auth/me');
            if (response.data.autherrormsg) {
                // console.log(response.data.errormsg)
                commit('SET_TOKEN', null);
                commit('SET_ERROR', response.data.autherrormsg);
                return false;
            } else {
                commit('SET_USER', response.data.user);
                commit('SET_TOKEN', response.data.token);
                return true;
            }
        },

        signOut({ commit }) {
            commit('SET_USER', '');
            commit('SET_TOKEN', null);
            commit('SET_ERROR', null);
            return;
        },

        setError({ commit }, msg) {
            commit('SET_ERROR', msg);
            return;
        },

        setSuccessMsg({ commit }, msg) {
            commit('SET_SUCCESSMSG', msg);
            return;
        },

        setToken({ commit }, token) {
            commit('SET_TOKEN', token);
            return;
        }
    }
};