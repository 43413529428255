import store from '../store';

export function addFieldListeners() {
    // console.log('setting addFieldListeners');
    let resizehandle = document.getElementById('field-' + store.state.fieldbuilder.currentfieldid + '-resize-handle');
    let movehandle = document.getElementById('field-' + store.state.fieldbuilder.currentfieldid + '-move-handle');
    resizehandle.addEventListener('mousedown', resizeHandleMouseDown);
    document.addEventListener('mousemove', resizeHandleMouseMove, true);
    resizehandle.addEventListener('mouseup', resizeHandleMouseUp, true);
    movehandle.addEventListener('mousedown', moveHandleMouseDown);
    document.addEventListener('mousemove', moveHandleMouseMove, true);
    movehandle.addEventListener('mouseup', moveHandleMouseUp, true);
    movehandle.addEventListener('dblclick', listenForKeyMove, true);
}

export function removeFieldListeners(fieldid) {
    store.commit('fieldbuilder/SET_MOUSEISDOWN', false);
    if (document.getElementById('field-' + fieldid + '-resize-handle')) {
        document.getElementById('field-' + fieldid + '-resize-handle').removeEventListener('mousedown', resizeHandleMouseDown);
        document.getElementById('field-' + fieldid + '-resize-handle').removeEventListener('mouseup', resizeHandleMouseUp);
    }
    if (document.getElementById('field-' + fieldid + '-move-handle')) {
        document.getElementById('field-' + fieldid + '-move-handle').removeEventListener('mousedown', moveHandleMouseDown);
        document.getElementById('field-' + fieldid + '-move-handle').removeEventListener('mouseup', moveHandleMouseUp);
    }
}

function resizeHandleMouseDown(event) {
    event.preventDefault();
    // console.log('mousedown');
    store.commit('fieldbuilder/SET_MOUSEISDOWN', true);
    store.commit('fieldbuilder/SET_FIELDRESIZING', true);
    let div = document.getElementById('field-' + store.state.fieldbuilder.currentfieldid);
    let input = document.getElementById('field-' + store.state.fieldbuilder.currentfieldid + '-input');
    let resizehandle = document.getElementById('field-' + store.state.fieldbuilder.currentfieldid + '-resize-handle');
    let movehandle = document.getElementById('field-' + store.state.fieldbuilder.currentfieldid + '-move-handle');
    let divposition = [event.clientX - div.offsetLeft, event.clientY - div.offsetTop, div.offsetTop];
    let inputdimensions = [event.clientX - input.offsetWidth, input.offsetHeight];
    let resizehandleoffset = [event.clientX - resizehandle.offsetLeft, event.clientY - resizehandle.offsetTop];
    let movehandleoffset = [event.clientX - movehandle.offsetLeft, input.offsetHeight - movehandle.offsetTop];
    div.setAttribute('divposition', JSON.stringify(divposition));
    div.setAttribute('inputdimensions', JSON.stringify(inputdimensions));
    div.setAttribute('resizehandleoffset', JSON.stringify(resizehandleoffset));
    div.setAttribute('movehandleoffset', JSON.stringify(movehandleoffset));
}

function resizeHandleMouseMove(event) {
    event.preventDefault();
    if (store.state.fieldbuilder.mouseisdown && store.state.fieldbuilder.fieldresizing) {
        // console.log('mousemove');
        let div = document.getElementById('field-' + store.state.fieldbuilder.currentfieldid);
        let input = document.getElementById('field-' + store.state.fieldbuilder.currentfieldid + '-input');
        let resizehandle = document.getElementById('field-' + store.state.fieldbuilder.currentfieldid + '-resize-handle');
        let movehandle = document.getElementById('field-' + store.state.fieldbuilder.currentfieldid + '-move-handle');
        let mouseposition = {
            x: event.clientX,
            y: event.clientY
        };
        let divpos = JSON.parse(div.getAttribute('divposition'));
        let dims = JSON.parse(div.getAttribute('inputdimensions'));
        let rho = JSON.parse(div.getAttribute('resizehandleoffset'));
        let mho = JSON.parse(div.getAttribute('movehandleoffset'));
        const newEvt = new MouseEvent("mouseup", {
            bubbles: true,
            cancelable: true,
        });

        div.style.top = (mouseposition.y - divpos[1]) + 'px';
        input.style.height = (divpos[2] - (mouseposition.y - divpos[1]) + dims[1]) + 'px';
        movehandle.style.top = input.offsetHeight - mho[1] + 'px';
        if (input.offsetHeight < (store.state.fieldbuilder.fontsize + 4)) {
            input.style.height = (store.state.fieldbuilder.fontsize + 4) + 'px';
            resizehandle.style.top = '-2.25vw';
            movehandle.style.top = '0px';
            resizehandle.dispatchEvent(newEvt);
        }

        input.style.width = (mouseposition.x - dims[0]) + 'px';
        resizehandle.style.left = (mouseposition.x - rho[0]) + 'px';
        movehandle.style.left = (mouseposition.x - mho[0]) + 'px';
        if (div.offsetWidth < 30) {
            input.style.width = '30px';
            resizehandle.style.left = '15px';
            movehandle.style.left = '15px';
            resizehandle.dispatchEvent(newEvt);
        }
    }
}

function resizeHandleMouseUp() {
    store.commit('fieldbuilder/SET_MOUSEISDOWN', false);
    store.commit('fieldbuilder/SET_FIELDRESIZING', false);
    let div = document.getElementById('field-' + store.state.fieldbuilder.currentfieldid);
    div.removeAttribute('inputdimensions');
    div.removeAttribute('resizehandleoffset');
    div.removeAttribute('movehandleoffset');
}

function moveHandleMouseDown(event) {
    event.preventDefault();
    store.commit('fieldbuilder/SET_MOUSEISDOWN', true);
    store.commit('fieldbuilder/SET_FIELDMOVING', true);
    let div = document.getElementById('field-' + store.state.fieldbuilder.currentfieldid);
    let movehandleoffset = [event.clientX - div.offsetLeft, event.clientY - div.offsetTop];
    div.setAttribute('movehandleoffset', JSON.stringify(movehandleoffset));
}

function moveHandleMouseMove(event) {
    event.preventDefault();
    if (store.state.fieldbuilder.mouseisdown && store.state.fieldbuilder.fieldmoving) {
        let div = document.getElementById('field-' + store.state.fieldbuilder.currentfieldid);
        let mouseposition = {
            x: event.clientX,
            y: event.clientY
        };
        let mho = JSON.parse(div.getAttribute('movehandleoffset'));
        div.style.left = (mouseposition.x - mho[0]) + 'px';
        div.style.top = (mouseposition.y - mho[1]) + 'px';
    }
}

function moveHandleMouseUp() {
    // console.log('mouseup');
    store.commit('fieldbuilder/SET_MOUSEISDOWN', false);
    store.commit('fieldbuilder/SET_FIELDMOVING', false);
    let div = document.getElementById('field-' + store.state.fieldbuilder.currentfieldid);
    div.removeAttribute('inputdimensions');
    div.removeAttribute('resizehandleoffset');
    div.removeAttribute('movehandleoffset');
}

function listenForKeyMove() {
    if (!window.keyMove) {
        window.keyMoveFunction = function(e) {
            let div = document.getElementById('field-' + store.state.fieldbuilder.currentfieldid);
            if (e.keyCode == '38') {
                // up arrow
                div.style.top = (div.offsetTop - 1) + 'px';
                e.preventDefault();
            } else if (e.keyCode == '40') {
                // down arrow
                div.style.top = (div.offsetTop + 1) + 'px';
                e.preventDefault();
            } else if (e.keyCode == '37') {
                // left arrow
                div.style.left = (div.offsetLeft - 1) + 'px';
                e.preventDefault();
            } else if (e.keyCode == '39') {
                // right arrow
                div.style.left = (div.offsetLeft + 1) + 'px';
                e.preventDefault();
            }
        };
        document.addEventListener('keydown', window.keyMoveFunction);
        document.getElementById('field-' + store.state.fieldbuilder.currentfieldid + '-input').style.border = '5px solid Orange';
        window.keyMove = true;
    } else {
        document.removeEventListener('keydown', window.keyMoveFunction);
        document.getElementById('field-' + store.state.fieldbuilder.currentfieldid + '-input').style.border = '5px solid rgb(119, 218, 0)';
        window.keyMove = false;
    }
}