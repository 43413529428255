import axios from 'axios';
import store from '../store';

export default {
    namespaced: true,
    state: {
        fieldidtoreorder: null,
        reorderdirection: null,
        showfieldorderreset: false,
        showfieldordersave: false,
        temporaryorder: null
    },
    getters: {
        fieldidtoreorder(state) {
            return state.fieldidtoreorder;
        },
        reorderdirection(state) {
            return state.reorderdirection;
        },
        showfieldorderreset(state) {
            return state.showfieldorderreset;
        },
        showfieldordersave(state) {
            return state.showfieldordersave;
        },
        temporaryorder(state) {
            return state.temporaryorder;
        }
    },
    mutations: {
        SET_FIELDIDTOREORDER(state, fieldidtoreorder) {
            state.fieldidtoreorder = fieldidtoreorder;
        },
        SET_REORDERDIRECTION(state, reorderdirection) {
            state.reorderdirection = reorderdirection;
        },
        SET_SHOWFIELDORDERRESET(state, showfieldorderreset) {
            state.showfieldorderreset = showfieldorderreset;
        },
        SET_SHOWFIELDORDERSAVE(state, showfieldordersave) {
            state.showfieldordersave = showfieldordersave;
        },
        SET_TEMPORARYORDER(state, temporaryorder) {
            state.temporaryorder = temporaryorder;
        }
    },
    actions: {
        changeTemporaryOrder({ commit, state }) {
            // console.log('fieldid: ' + state.fieldidtoreorder + ' direction: ' + state.reorderdirection);
            let currentindex = state.temporaryorder.findIndex(obj => obj === state.fieldidtoreorder);
            // console.log('current index: ' + currentindex + ' indexlength: ' + state.temporaryorder.length);
            let newtemporder = [];
            let newindex;
            let proceed = true;
            if (state.reorderdirection === 'up') {
                if (currentindex > 0) {
                    newindex = currentindex - 1;
                } else {
                    proceed = false;
                }
                if (proceed) {
                    for (var i = 0; i < state.temporaryorder.length;) {
                        if (i === currentindex) {
                            i++;
                        } else if (i === newindex) {
                            newtemporder.push(state.temporaryorder[currentindex]);
                            newtemporder.push(state.temporaryorder[i]);
                            i++;
                        } else {
                            newtemporder.push(state.temporaryorder[i]);
                            i++;
                        }
                    }
                }
            } else if (state.reorderdirection === 'down') {
                // down
                if (currentindex < (state.temporaryorder.length - 1)) {
                    newindex = currentindex + 1;
                } else {
                    proceed = false;
                }
                if (proceed) {
                    for (var j = 0; j < state.temporaryorder.length;) {
                        if (j === currentindex) {
                            newtemporder.push(state.temporaryorder[j + 1]);
                            newtemporder.push(state.temporaryorder[currentindex]);
                            j++;
                        } else if (j === newindex) {
                            j++;
                        } else {
                            newtemporder.push(state.temporaryorder[j]);
                            j++;
                        }
                    }
                }

            }

            // console.log('newtemporder: ' + newtemporder);
            if (proceed) {
                setTimeout(function() {
                    commit('SET_TEMPORARYORDER', newtemporder);
                    commit('SET_SHOWFIELDORDERSAVE', true);
                    commit('SET_SHOWFIELDORDERRESET', true);
                }, 200);
            }
            setTimeout(function() {
                commit('SET_FIELDIDTOREORDER', null);
            }, 400);

        },

        setTemporaryOrder({ commit, rootGetters }) {
            // sort fields by order
            let tempfields = [];
            rootGetters['fieldbuilder/fields'].forEach(field => {
                tempfields.push(field);
            });
            let sortedfields = tempfields.sort(function(a, b) {
                // console.log('a.order: ' + a.order + ' b.order: ' + b.order);
                return a.order - b.order;
            });
            let temporaryorder = [];
            sortedfields.forEach(field => {
                // console.log('field.id: ' + field.id);
                temporaryorder.push(field.id);
            });
            // console.log('sorted fields: ' + JSON.stringify(sortedfields));
            commit('SET_TEMPORARYORDER', temporaryorder);
        },

        resetFieldOrder({ commit, state, dispatch }) {
            if (state.showfieldorderreset) {
                // console.log('reset field order');
                dispatch('setTemporaryOrder');
                commit('SET_SHOWFIELDORDERSAVE', false);
                commit('SET_SHOWFIELDORDERRESET', false);
            }
        },

        async commitFieldOrder({ commit, state }) {
            if (state.showfieldordersave) {
                store.dispatch('loadingmsg/turnOnLoadingMsg');
                // console.log('commit field order');
                let response = await axios.post('docs/savefieldorder', {
                    field_order: state.temporaryorder
                });
                if (response && response.data.errormsg) {
                    store.dispatch('loadingmsg/turnOffLoadingMsg');
                    store.dispatch('notification/processErrorMessage', response.data.errormsg);
                } else if (response && response.data.message) {
                    commit('SET_SHOWFIELDORDERSAVE', false);
                    commit('SET_SHOWFIELDORDERRESET', false);
                    store.dispatch('fieldbuilder/getFieldArray').then((res) => {
                        if (res) {
                            store.dispatch('loadingmsg/turnOffLoadingMsg');
                            store.dispatch('notification/processSuccessMessage', response.data.message);
                        }
                    });
                }
            }
        }
    }
};