import axios from 'axios';
import store from '../store';
// import router from '../router';

export default {
    namespaced: true,
    state: {
        base64blob: null,
        currentfieldindex: null,
        currentfieldorder: 0,
        documentdata: null,
        documentheight: '',
        documentwidth: '',
        documentviewed: false,
        fields: [],
        fieldscompleted: 0,
        fieldvalues: [],
        flashinput: null,
        hasflashed: false,
        showsignaturepad: false,
        showviewdownload: false,
        sigdata: null
    },
    getters: {
        base64blob(state) {
            return state.base64blob;
        },
        currentfieldindex(state) {
            return state.currentfieldindex;
        },
        currentfieldorder(state) {
            return state.currentfieldorder;
        },
        documentdata(state) {
            return state.documentdata;
        },
        documentheight(state) {
            return state.documentheight;
        },
        documentwidth(state) {
            return state.documentwidth;
        },
        documentviewed(state) {
            return state.documentviewed;
        },
        fields(state) {
            return state.fields;
        },
        fieldscompleted(state) {
            return state.fieldscompleted;
        },
        fieldvalues(state) {
            return state.fieldvalues;
        },
        flashinput(state) {
            return state.flashinput;
        },
        hasflashed(state) {
            return state.hasflashed;
        },
        showsignaturepad(state) {
            return state.showsignaturepad;
        },
        showviewdownload(state) {
            return state.showviewdownload;
        },
        sigdata(state) {
            return state.sigdata;
        },
    },
    mutations: {
        SET_BASE64BLOB(state, base64blob) {
            state.base64blob = base64blob;
        },
        SET_CURRENTFIELDINDEX(state, currentfieldindex) {
            state.currentfieldindex = currentfieldindex;
        },
        SET_CURRENTFIELDORDER(state, currentfieldorder) {
            state.currentfieldorder = currentfieldorder;
        },
        SET_DOCUMENTDATA(state, documentdata) {
            state.documentdata = documentdata;
        },
        SET_DOCUMENTHEIGHT(state, documentheight) {
            state.documentheight = documentheight;
        },
        SET_DOCUMENTWIDTH(state, documentwidth) {
            state.documentwidth = documentwidth;
        },
        SET_DOCUMENTVIEWED(state, documentviewed) {
            state.documentviewed = documentviewed;
        },
        SET_FIELDS(state, fields) {
            state.fields = fields;
        },
        SET_FIELDSCOMPLETED(state, fieldscompleted) {
            state.fieldscompleted = fieldscompleted;
        },
        SET_FIELDVALUES(state, fieldvalues) {
            state.fieldvalues = fieldvalues;
        },
        SET_FLASHINPUT(state, flashinput) {
            state.flashinput = flashinput;
        },
        SET_HASFLASHED(state, hasflashed) {
            state.hasflashed = hasflashed;
        },
        SET_SHOWSIGNATUREPAD(state, showsignaturepad) {
            state.showsignaturepad = showsignaturepad;
        },
        SET_SHOWVIEWDOWNLOAD(state, showviewdownload) {
            state.showviewdownload = showviewdownload;
        },
        SET_SIGDATA(state, sigdata) {
            state.sigdata = sigdata;
        },
    },
    actions: {
        async fetchDocument({ commit, rootGetters }) {
            let response = await axios.post('docs/fillablegetdocument', {
                file_id: rootGetters['fillabledoc/documentid']
            });
            if (response && response.data.errormsg) {
                store.dispatch('fillabledoc/setErrorMsg', response.data.errormsg);
                return false;
            } else if (response.data.document) {
                let d = response.data.document;
                commit('SET_DOCUMENTWIDTH', d.width);
                commit('SET_DOCUMENTHEIGHT', d.height);
                commit('SET_DOCUMENTDATA', d.document);
                commit('SET_FIELDS', d.fields);
                return true;
            }
        },

        getFieldIndex({ commit, state }) {
            let indexvalue;
            for (var i = 0; i < state.fields.length; i++) {
                if (state.fields[i].order === state.currentfieldorder) {
                    indexvalue = i;
                    break;
                }
            }
            commit('SET_CURRENTFIELDINDEX', indexvalue);
            return;
        },

        highlightCurrentField({ state }) {
            if (document.getElementById('field-display-input-' + state.currentfieldindex)) {
                document.getElementById('field-display-input-' + state.currentfieldindex).style.backgroundColor = '#ffff9c';
            }
        },

        setFieldValue({ state, dispatch }) {
            dispatch('getFieldIndex').then((index) => {
                if (typeof state.fieldvalues[index] === 'undefined') {
                    return;
                } else {
                    let fieldkey = state.fields[index].data_name;
                    if (state.fields[index].type === 'text') {
                        document.getElementById('field-display-input-' + index).value = state.fieldvalues[index][fieldkey];
                        return;
                    } else if (state.fields[index].type === 'date') {
                        if (state.fieldvalues[index] != '') {
                            let datearr = state.fieldvalues[index].fieldkay.split('-');
                            let datedisplay = datearr[1] + '/' + datearr[2] + '/' + datearr[0];
                            document.getElementById('field-display-input-' + index).value = datedisplay;
                        }
                        return;
                    }
                }
            });
        },

        setFieldValues({ state }) {
            for (var i = 0; i < state.fieldvalues.length; i++) {
                // console.log('i: ' + i + ' ' + JSON.stringify(state.fieldvalues[i]));
                if (state.fieldvalues[i]) {
                    document.getElementById('field-display-input-' + i).value = state.fieldvalues[i][state.fields[i].data_name];
                }
            }
            return;
        },

        async storeFieldValues({ state }) {
            store.dispatch('loadingmsg/turnOnLoadingMsg');
            let random = window.location.pathname.split('/')[3];
            let response = await axios.post('docs/fillablestorefieldvalues', {
                random: random,
                fieldvalues: state.fieldvalues
            });
            if (response.data.errormsg) {
                // console.log(response.data.errormsg);
                store.dispatch('fillabledoc/setErrorMsg', response.data.errormsg);
                store.dispatch('loadingmsg/turnOffLoadingMsg');
                return false;
            }
            if (response.data.successmsg) {
                // console.log(response.data.successmsg);
                store.dispatch('loadingmsg/turnOffLoadingMsg');
                return true;
            }
        },

        async storeSignature({ state }) {
            // console.log(state.sigdata);
            // store.dispatch('loadingmsg/turnOnLoadingMsg');
            let random = window.location.pathname.split('/')[3];
            let response = await axios.post('docs/fillablestoresignature', {
                random: random,
                signature_data: state.sigdata
            });
            if (response.data.errormsg) {
                // console.log(response.data.errormsg);
                store.dispatch('fillabledoc/setErrorMsg', response.data.errormsg);
                store.dispatch('loadingmsg/turnOffLoadingMsg');
                return false;
            }

            if (response.data.successmsg) {
                // console.log(response.data.successmsg);
                store.dispatch('loadingmsg/turnOffLoadingMsg');
                return true;
            }

        },

        async modalSubmitClicked({ commit }) {
            // console.log('submit clicked.');
            let random = window.location.pathname.split('/')[3];
            store.dispatch('loadingmsg/turnOnLoadingMsg');
            let response = await axios.post('docs/fillablebuildfinal', {
                random: random,
            });
            if (response.data.errormsg) {
                // console.log(response.data.errormsg);
                store.dispatch('fillabledoc/setErrorMsg', response.data.errormsg);
                store.dispatch('loadingmsg/turnOffLoadingMsg');
                return false;
            }

            if (response.data.base64_blob) {
                // set document data for download
                commit('SET_BASE64BLOB', response.data.base64_blob);
                commit('SET_SHOWVIEWDOWNLOAD', true);
                store.dispatch('loadingmsg/turnOffLoadingMsg');
                return true;
            }
        },

        formatBackButton({ state }, enabled) {
            if (enabled) {
                if(document.getElementById('back-button-disabled-' + state.currentfieldindex)) {
                    document.getElementById('back-button-disabled-' + state.currentfieldindex).classList.remove('show');
                    document.getElementById('back-button-disabled-' + state.currentfieldindex).classList.add('hide');
                }
                if(document.getElementById('back-button-' + state.currentfieldindex)) {
                    document.getElementById('back-button-' + state.currentfieldindex).classList.remove('hide');
                    document.getElementById('back-button-' + state.currentfieldindex).classList.add('show');
                }

            } else {
                document.getElementById('back-button-disabled-' + state.currentfieldindex).classList.remove('hide');
                document.getElementById('back-button-disabled-' + state.currentfieldindex).classList.add('show');
                document.getElementById('back-button-' + state.currentfieldindex).classList.remove('show');
                document.getElementById('back-button-' + state.currentfieldindex).classList.add('hide');
            }
        },

        formatNextButton({ state }, enabled) {
            if (enabled) {
                if(document.getElementById('next-button-disabled-' + state.currentfieldindex)) {
                    document.getElementById('next-button-disabled-' + state.currentfieldindex).classList.remove('show');
                    document.getElementById('next-button-disabled-' + state.currentfieldindex).classList.add('hide');

                }
                if(document.getElementById('next-button-' + state.currentfieldindex)) {
                    document.getElementById('next-button-' + state.currentfieldindex).classList.remove('hide');
                    document.getElementById('next-button-' + state.currentfieldindex).classList.add('show');
                }

            } else {
                document.getElementById('next-button-disabled-' + state.currentfieldindex).classList.remove('hide');
                document.getElementById('next-button-disabled-' + state.currentfieldindex).classList.add('show');
                document.getElementById('next-button-' + state.currentfieldindex).classList.remove('show');
                document.getElementById('next-button-' + state.currentfieldindex).classList.add('hide');
            }
        },



    }
};
