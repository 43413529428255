import Vue from 'vue';
import Vuex from 'vuex';
import anonauth from './anonauth';
import auth from './auth';
import docsupload from './docsupload';
import fieldbuilder from './fieldbuilder';
import fieldorder from './fieldorder';
import fillableclient from './fillableclient';
import fillabledoc from './fillabledoc';
import fillabledocsetup from './fillabledocsetup';
import fillabledocmodal from './fillabledocmodal';
import fillablepage from './fillablepage';
import loadingmsg from './loadingmsg';
import notification from './notification';
import selectedfile from './selectedfile';

Vue.use(Vuex);

export default new Vuex.Store({
    state: {},
    mutations: {},
    actions: {},
    modules: {
        anonauth,
        auth,
        docsupload,
        fieldbuilder,
        fieldorder,
        fillableclient,
        fillabledoc,
        fillabledocsetup,
        fillabledocmodal,
        fillablepage,
        loadingmsg,
        notification,
        selectedfile
    }
});