import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '../store';
import axios from 'axios';
const DocumentUpload = () => import( /* webpackChunkName: "DocumentUpload" */ '../views/DocumentUpload.vue');
const DocumentFillable = () => import( /* webpackChunkName: "DocumentFillable" */ '../views/DocumentFillable.vue');
const DocumentFillableSetup = () => import( /* webpackChunkName: "DocumentFillableSetup" */ '../views/DocumentFillableSetup.vue');
const Home = () => import( /* webpackChunkName: "home" */ '../views/Home.vue');
const FillablePage = () => import( /* webpackChunkName: "FillablePage" */ '../views/FillablePage.vue');
const LinkError = () => import( /* webpackChunkName: "LinkError" */ '../views/LinkError.vue');
const SignIn = () => import( /* webpackChunkName: "SignIn" */ '../views/SignIn.vue');

Vue.use(VueRouter);
const routes = [{
    path: '/',
    name: 'Home',
    component: Home,
}, {
    path: '/docs/upload/:random',
    name: 'DocumentUpload',
    component: DocumentUpload,
}, {
    path: '/docs/fillable/:random',
    name: 'DocumentFillable',
    component: DocumentFillable,
}, {
    path: '/docs/fillablesetup',
    name: 'DocumentFillableSetup',
    component: DocumentFillableSetup,
}, {
    path: '/docs/fillablepage',
    name: 'FillablePage',
    component: FillablePage,
}, {
    path: '/linkerror',
    name: 'LinkError',
    component: LinkError,
}, {
    path: '/r/:random',
    name: 'ProcessRandom'
}, {
    path: '/signin',
    name: 'SignIn',
    component: SignIn,
}];
const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
});
// only for paths needing JWTs to access front-end views
router.beforeEach((to, from, next) => {
    if (to.name == ('Home' || 'DocumentFillableSetup')) { // Admin Routes
        // console.log('to.name: ' + to.name);
        // console.log('JWT path');
        var storedtoken = (localStorage.getItem('token')) ?
            localStorage.getItem('token') : null;

        if (storedtoken) {
            // console.log('found storedtoken: ' + storedtoken);
            // but is in set in the headers?
            // console.log('Bearer: ' + axios.defaults.headers.common.Authorization);
            if (!axios.defaults.headers.common.Authorization) {
                axios.defaults.headers.common.Authorization = `Bearer ${storedtoken}`;
            }
            store.dispatch('auth/attempt').then((res) => {
                if (res) {
                    // auth worked
                    if (to.name == 'SignIn') {
                        return next({
                            name: 'Home'
                        });
                    } else {
                        return next();
                    }
                } else {
                    // auth did not work
                    // console.log('routing to sign in');
                    localStorage.removeItem('token');
                    router.replace({
                        name: 'SignIn'
                    });
                }
            });

        } else if (!storedtoken) {
            // console.log('no storedtoken');
            // console.log('routing to sign in');
            router.replace({
                name: 'SignIn'
            });
        }

    } else if (to.name == 'DocumentFillable') { // routes protected by anonJWT
        // console.log('to.name: ' + to.name);
        var storedanontoken = (localStorage.getItem('anontoken')) ?
            localStorage.getItem('anontoken') : null;

        if (storedanontoken) {
            // console.log('anontoken found');
            store.commit('anonauth/SET_ANONTOKEN', storedanontoken);
            store.dispatch('anonauth/attempt').then((res) => {
                if (res) {
                    return next();
                } else {
                    router.replace({
                        name: 'FillablePage'
                    });
                }
            });

        } else {
            store.dispatch('anonauth/getToken').then((res) => {
                if (res) {
                    return next();
                }
            });
        }

    } else if (to.name == 'ProcessRandom') {
        let random = to.params.random;
        // console.log('random: ' + to.params.random);
        axios.post('docs/checkurl', {
            randomurl: random
        }).then((response) => {
            if (response.data.feature) {
                if (response.data.feature === 'fillabledoc') {
                    router.push({ path: `/docs/fillable/${random}` });
                }
                if (response.data.feature === 'upload') {
                    router.push({ path: `/docs/upload/${random}` });
                }
            } else {
                store.commit('fillablepage/SET_PAGEMESSAGE', null);
                store.commit('fillablepage/SET_PAGEERROR', 'This link is not valid.');
                router.replace({
                    name: 'FillablePage'
                });
            }
        }).catch((e) => {
            console.log(e);
        });

    } else {
        // console.log('to.name: ' + to.name);
        return next();
    }
});
// window.onload = function() {
//   handleConnection();
// }
window.addEventListener('online', handleConnection);
window.addEventListener('offline', handleConnection);

function handleConnection() {
    if (navigator.onLine) {
        isReachable(getServerUrl()).then(function(online) {
            if (online) {
                // handle online status
                // console.log('online');
                store.dispatch('loadingmsg/setOffline', false);
                if (navigator.serviceWorker) {
                    navigator.serviceWorker.controller.postMessage({
                        command: 'setCache',
                        name: '/offlinestatus',
                        value: 'false'
                    });
                }
            } else {
                // console.log('no connectivity');
            }
        });
    } else {
        // handle offline status
        // console.log('offline');
        store.dispatch('loadingmsg/setOffline', true);
        if (navigator.serviceWorker) {
            navigator.serviceWorker.controller.postMessage({
                command: 'setCache',
                name: '/offlinestatus',
                value: 'true'
            });
        }
    }
}

function isReachable(url) {
    /**
     * Note: fetch() still "succeeds" for 404s on subdirectories,
     * which is ok when only testing for domain reachability.
     *
     * Example:
     *   https://google.com/noexist does not throw
     *   https://noexist.com/noexist does throw
     */
    return fetch(url, {
        method: 'HEAD',
        mode: 'no-cors'
    }).then(function(resp) {
        return resp && (resp.ok || resp.type === 'opaque');
    });
    // .catch(function(err) {
    //   // console.warn('[conn test failure]:', err);
    // });
}

function getServerUrl() {
    return window.location.origin;
}
export default router;