export default {
    namespaced: true,
    state: {
        errormsg: null,
        successmsg: null
    },
    getters: {
        errormsg(state) {
            return state.errormsg;
        },
        successmsg(state) {
            return state.successmsg;
        }
    },
    mutations: {
        SET_ERRORMSG(state, errormsg) {
            state.errormsg = errormsg;
        },
        SET_SUCCESSMSG(state, successmsg) {
            state.successmsg = successmsg;
        }
    },
    actions: {
        processErrorMessage({ commit }, errormessage) {
            commit('SET_SUCCESSMSG', null);
            commit('SET_ERRORMSG', errormessage);
            setTimeout(function() {
                commit('SET_ERRORMSG', null);
            }, 3000);
            setTimeout(function() {
                if (document.getElementById('errormsg')) {
                    document.getElementById('errormsg').classList.toggle('fade');
                }
            }, 2000);
        },

        processSuccessMessage({ commit }, successmessage) {
            commit('SET_ERRORMSG', null);
            commit('SET_SUCCESSMSG', successmessage);
            setTimeout(function() {
                commit('SET_SUCCESSMSG', null);
            }, 3000);
            setTimeout(function() {
                if (document.getElementById('successmsg')) {
                    document.getElementById('successmsg').classList.toggle('fade');
                }
            }, 2000);
        }
    }
};