import Vue from 'vue';
import App from './App.vue';
import './plugins/bootstrap-vue.js';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import router from './router';
import store from './store';
import axios from 'axios';
// import './registerServiceWorker'

// subscriber sets and updates token into LocalStorage, and places
// into headers for each request
require('@/store/subscriber');

axios.defaults.baseURL = '/api/';
Vue.config.productionTip = false;
Vue.component('font-awesome-icon', FontAwesomeIcon);

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app');

// this handles the updating of jwts on requests
axios.interceptors.response.use(function(response) {
    // console.log('intercepted response')
    if (response.data.token) {
        store.commit('auth/SET_TOKEN', response.data.token);
    }
    if (response.data.anontoken) {
        store.commit('anonauth/SET_ANONTOKEN', response.data.anontoken);
    }
    if (response.data.autherrormsg) {
        // console.log('main.js '+response.data.error)
        if (response.data.autherrormsg.search('Invalid token') != -1) {
            store.dispatch('auth/setError', response.data.errormsg);
            store.dispatch('auth/setToken', null);
            router.push('SignIn').catch(() => {});
        }
        if (response.data.autherrormsg.search('Invalid anontoken') != -1) {
            store.commit('anonauth/SET_ERROR', response.data.errormsg);
            store.commit('auth/SET_ANONTOKEN', null);
            router.push('/linkerror').catch(() => {});
        }
        if (response.data.autherrormsg.search('No auth token') != -1) {
            store.dispatch('loadingmsg/turnOnLoadingMsg');
            if (localStorage.getItem('token')) {
                // console.log('loading up locally found token.');
                store.commit('auth/SET_TOKEN', localStorage.getItem('token'));
                let thispath = window.location.pathname;
                router.push('/').then(() => {
                    router.push(thispath);
                    if (thispath != '/docs/fillablesetup') {
                        store.dispatch('loadingmsg/turnOffLoadingMsg');
                    }
                });
            } else {
                store.dispatch('auth/setError', response.data.autherrormsg);
                store.dispatch('auth/setToken', null);
                router.push('/signin').catch(() => {});
                store.dispatch('loadingmsg/turnOffLoadingMsg');
            }
        }
        if (response.data.autherrormsg.search('No auth anontoken') != -1) {
            store.dispatch('loadingmsg/turnOnLoadingMsg');
            if (localStorage.getItem('anontoken')) {
                // console.log('loading up locally found token.');
                store.commit('anonauth/SET_ANONTOKEN', localStorage.getItem('anontoken'));
            }
            let thispath = window.location.pathname;
            router.push(thispath);
            store.dispatch('loadingmsg/turnOffLoadingMsg');
        }
    }
    return response;
}, function(error) {
    return Promise.reject(error);
});