import axios from 'axios';

export default {
    namespaced: true,
    state: {
        // randomurl: null,
        document_upload_record_no: null,
        description: null,
        error: '',
        showdone: 'never',
        showdescriptioncontainer: 'no',
        showuploadbuttoncontainer: 'no',
        showfilebuttoncontainer: 'no',
        showselectedfile: 'no',
        filenamearray: [],
        showuploadingmessage: 'no'

    },

    getters: {
        // randomurl(state) {
        //     return state.randomurl;
        // },
        // 
        document_upload_record_no(state) {
            return state.document_upload_record_no;
        },

        description(state) {
            return state.description;
        },

        error(state) {
            return state.error;
        },

        showdone(state) {
            return state.showdone;
        },

        showdescriptioncontainer(state) {
            return state.showdescriptioncontainer;
        },

        showuploadbuttoncontainer(state) {
            return state.showuploadbuttoncontainer;
        },

        showfilebuttoncontainer(state) {
            return state.showfilebuttoncontainer;
        },

        showselectedfile(state) {
            return state.showselectedfile;
        },

        filenamearray(state) {
            return state.filenamearray;
        },

        showuploadingmessage(state) {
            return state.showuploadingmessage;
        },


    },

    mutations: {
        // changes state items (current state, any data passed)
        // SET_RANDOMURL(state, randomurl) {
        //     state.randomurl = randomurl;
        // },

        SET_DOCUMENTUPLOADRECORDNO(state, document_upload_record_no) {
            state.document_upload_record_no = document_upload_record_no;
        },

        SET_DESCRIPTION(state, description) {
            state.description = description;
        },

        SET_ERROR(state, error) {
            state.error = error;
        },

        SET_SHOWDONE(state, showdone) {
            state.showdone = showdone;
        },

        SET_SHOWDESCRIPTIONCONTAINER(state, showdescriptioncontainer) {
            state.showdescriptioncontainer = showdescriptioncontainer;
        },

        SET_SHOWUPLOADBUTTONCONTAINER(state, showuploadbuttoncontainer) {
            state.showuploadbuttoncontainer = showuploadbuttoncontainer;
        },

        SET_SHOWFILEBUTTONCONTAINER(state, showfilebuttoncontainer) {
            state.showfilebuttoncontainer = showfilebuttoncontainer;
        },

        SET_SHOWSELECTEDFILE(state, showselectedfile) {
            state.showselectedfile = showselectedfile;
        },

        SET_FILENAMEARRAY(state, filenamearray) {
            state.filenamearray = filenamearray;
        },

        SET_SHOWUPLOADINGMESSAGE(state, showuploadingmessage) {
            state.showuploadingmessage = showuploadingmessage;
        },



    },

    actions: {
        async checkUrl({ commit }, randomurl) {

            let response = await axios.post('docs/checkurl', {
                feature: 'upload',
                randomurl: randomurl
            });
            if (response.data.error) {
                commit('SET_ERROR', response.data.error);
                commit('SET_SHOWDESCRIPTIONCONTAINER', 'no');
                commit('SET_SHOWUPLOADBUTTONCONTAINER', 'no');
                commit('SET_SHOWDONE', 'never');
                return false;
            } else {
                commit('SET_ERROR', '');
                commit('SET_SHOWDESCRIPTIONCONTAINER', 'yes');
                commit('SET_SHOWUPLOADBUTTONCONTAINER', 'yes');
                commit('SET_SHOWDONE', 'no');
                commit('SET_DOCUMENTUPLOADRECORDNO', response.data.record_no);
                commit('SET_DESCRIPTION', response.data.description);
                return true;

            }

        },

        async sendFile({ commit, state }, selectedfile) {
            // console.log('record_no: '+state.document_upload_record_no)
            let formData = new FormData();
            formData.append('file', selectedfile);
            formData.append('record_no', state.document_upload_record_no);

            let response = await axios.post('docs/uploadfile',
                formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                }
            );

            if (response && response.data.message === 'Success.') {
                commit('SET_ERROR', '');
                commit('SET_SHOWFILEBUTTONCONTAINER', 'no');
                commit('SET_SHOWUPLOADBUTTONCONTAINER', 'no');
                commit('SET_SHOWSELECTEDFILE', 'no');
                commit('SET_SHOWDONE', 'yes');

            } else if (response.data.error) {
                commit('SET_ERROR', response.data.error);
                commit('SET_SHOWFILEBUTTONCONTAINER', 'no');
                commit('SET_SHOWUPLOADBUTTONCONTAINER', 'yes');
                commit('SET_SHOWSELECTEDFILE', 'no');

            } else {
                // console.log(response)
            }

        },


        setVar({ commit }, v) {
            // console.log(v);
            var s;
            switch (v.variable) {
                case 'showuploadbuttoncontainer':
                    s = 'SET_SHOWUPLOADBUTTONCONTAINER';
                    break;

                case 'showfilebuttoncontainer':
                    s = 'SET_SHOWFILEBUTTONCONTAINER';
                    break;

                case 'showselectedfile':
                    s = 'SET_SHOWSELECTEDFILE';
                    break;

                case 'error':
                    s = 'SET_ERROR';
                    break;

                case 'showdone':
                    s = 'SET_SHOWDONE';
                    break;

                case 'showuploadingmessage':
                    s = 'SET_SHOWUPLOADINGMESSAGE';
                    break;

            }

            commit(`${s}`, v.value);

        },

        pushFileNameArray({ state, commit }, filename) {
            var arr = state.filenamearray;
            arr.push(filename);
            commit('SET_FILENAMEARRAY', arr);
        },

    }
};