import axios from 'axios';
import store from '../store';
import router from '../router';
import { waitForIt } from '../library/FillableDoc.js';

export default {
    namespaced: true,
    state: {
        code: '',
        codeisvalidated: false,
        consentgranted: false,
        documentdescription: '',
        documentid: '',
        emailaddress: '',
        emailaddressisvalidated: false,
        emailaddressisverified: false,
        errormsg: null,
        ismobile: true,
        randomurl: '',
        showmodalcode: false,
        showmodalintro: false,
        showmodalconsent: false,
        tooltip: null,
        unioninfo: ''
    },
    getters: {
        code(state) {
            return state.code;
        },
        codeisvalidated(state) {
            return state.codeisvalidated;
        },
        consentgranted(state) {
            return state.consentgranted;
        },
        documentdescription(state) {
            return state.documentdescription;
        },
        documentid(state) {
            return state.documentid;
        },
        emailaddress(state) {
            return state.emailaddress;
        },
        emailaddressisvalidated(state) {
            return state.emailaddressisvalidated;
        },
        emailaddressisverified(state) {
            return state.emailaddressisverified;
        },
        errormsg(state) {
            return state.errormsg;
        },
        ismobile(state) {
            return state.ismobile;
        },
        randomurl(state) {
            return state.randomurl;
        },
        showmodalcode(state) {
            return state.showmodalcode;
        },
        showmodalintro(state) {
            return state.showmodalintro;
        },
        showmodalconsent(state) {
            return state.showmodalconsent;
        },
        tooltip(state) {
            return state.tooltip;
        },
        unioninfo(state) {
            return state.unioninfo;
        },
    },
    mutations: {
        SET_CODE(state, code) {
            state.code = code;
        },
        SET_CODEISVALIDATED(state, codeisvalidated) {
            state.codeisvalidated = codeisvalidated;
        },
        SET_CONSENTGRANTED(state, consentgranted) {
            state.consentgranted = consentgranted;
        },
        SET_DOCUMENTDESCRIPTION(state, documentdescription) {
            state.documentdescription = documentdescription;
        },
        SET_DOCUMENTID(state, documentid) {
            state.documentid = documentid;
        },
        SET_EMAILADDRESS(state, emailaddress) {
            state.emailaddress = emailaddress;
        },
        SET_EMAILADDRESSISVALIDATED(state, emailaddressisvalidated) {
            state.emailaddressisvalidated = emailaddressisvalidated;
        },
        SET_EMAILADDRESSISVERIFIED(state, emailaddressisverified) {
            state.emailaddressisverified = emailaddressisverified;
        },
        SET_ERRORMSG(state, errormsg) {
            state.errormsg = errormsg;
        },
        SET_ISMOBILE(state, ismobile) {
            state.ismobile = ismobile;
        },
        SET_RANDOMURL(state, randomurl) {
            state.randomurl = randomurl;
        },
        SET_SHOWMODALCODE(state, showmodalcode) {
            state.showmodalcode = showmodalcode;
        },
        SET_SHOWMODALINTRO(state, showmodalintro) {
            state.showmodalintro = showmodalintro;
        },
        SET_SHOWMODALCONSENT(state, showmodalconsent) {
            state.showmodalconsent = showmodalconsent;
        },
        SET_TOOLTIP(state, tooltip) {
            state.tooltip = tooltip;
        },
        SET_UNIONINFO(state, unioninfo) {
            state.unioninfo = unioninfo;
        },
    },
    actions: {
        async modalEmailCodeClicked({ commit, state }) {
            if (state.emailaddress && state.emailaddressisvalidated) {
                // console.log('Email address is: ' + state.emailaddress);
                let response = await axios.post('docs/fillableemailcode', {
                    email_address: state.emailaddress,
                    urlaccessed: window.location.href
                });

                if (response.data.errormsg) {
                    // console.log(response.data.errormsg);
                    commit('SET_ERRORMSG', response.data.errormsg);
                    return false;
                } else if (response.data.successmsg) {
                    // console.log(response.data.successmsg);
                    return true;
                }
            }

        },

        async modalCodeClicked({ commit, state }) {
            if (state.code && state.codeisvalidated) {
                let response = await axios.post('docs/fillablecheckcode', {
                    code: state.code,
                    email_address: state.emailaddress
                });

                if (response.data.errormsg) {
                    // console.log(response.data.errormsg);
                    commit('SET_ERRORMSG', response.data.errormsg);
                    return false;
                } else if (response.data.successmsg) {
                    console.log(response.data.successmsg);
                    return true;
                }
            }
        },

        async modalAgreeClicked({ commit }) {
            store.dispatch('loadingmsg/turnOnLoadingMsg');
            let response = await axios.post('docs/fillableconsentgranted');

            if (response.data.successmsg) {
                // console.log(response.data.successmsg);
                commit('SET_SHOWMODALCONSENT', false);
                commit('SET_CONSENTGRANTED', true);
                store.dispatch('loadingmsg/turnOffLoadingMsg');

            } else if (response.data.errormsg) {
                console.log(response.data.errormsg);
                store.dispatch('loadingmsg/turnOffLoadingMsg');

            }
        },

        async modalDisagreeClicked({ state }) {
            store.dispatch('loadingmsg/turnOnLoadingMsg');
            let response = await axios.post('docs/fillableconsentdeclined', {
                randomurl: state.randomurl,
                urlpath: window.location.href
            });

            if (response.data.consent_declined) {
                console.log(response.data.consent_declined);
                store.commit('fillablepage/SET_PAGEERROR', null);
                store.commit('fillablepage/SET_PAGEMESSAGE', 'Thank you we have recorded your decline of consent to do business electronically with us. You may close your browser.');
                router.replace({
                    name: 'FillablePage'
                });
                store.dispatch('loadingmsg/turnOffLoadingMsg');

            } else if (response.data.errormsg) {
                console.log(response.data.errormsg);
                store.dispatch('loadingmsg/turnOffLoadingMsg');
            }
        },

        setTooltip({ commit, state }, tooltip) {
            if (tooltip) {
                waitForIt('.tooltip-mount').then((tt) => {
                    let el1 = document.getElementById('tooltip-container');
                    if (!state.ismobile) {
                        // container
                        el1.style.width = '428px';
                        el1.style.height = '926px';
                        el1.style.margin = 'auto';

                        // tooltip
                        tt.style.width = '321px';
                        tt.style.marginLeft = '51px';
                        tt.style.top = 'calc((' + (926 - tt.offsetHeight) + 'px) / 2)';
                    } else {
                        tt.style.top = (window.innerHeight - tt.offsetHeight) / 2 + 'px';
                    }
                });
                commit('SET_TOOLTIP', tooltip);
            } else {
                if (!store.state.fillableclient.hasflashed && state.consentgranted) {
                    commit('SET_TOOLTIP', tooltip);
                    store.dispatch('fillableclient/highlightCurrentField');

                    // flash the input field twice
                    if (document.getElementById('field-input-0')) {
                        store.state.fillableclient.flashinput = setTimeout(function() {
                            document.getElementById('field-input-0').style.backgroundColor = '#ffff9c';
                            setTimeout(function() {
                                document.getElementById('field-input-0').style.backgroundColor = 'transparent';
                                setTimeout(function() {
                                    document.getElementById('field-input-0').style.backgroundColor = '#ffff9c';
                                    setTimeout(function() {
                                        document.getElementById('field-input-0').style.backgroundColor = 'transparent';
                                    }, 100);
                                }, 100);
                            }, 100);
                        }, 1000);
                        store.commit('fillableclient/SET_HASFLASHED', true);
                    }
                } else {
                    commit('SET_TOOLTIP', tooltip);
                }
            }
        },

        setErrorMsg({ commit, state }, errormsg) {
            if (errormsg) {
                waitForIt('.errormsg-mount').then((em) => {
                    let el1 = document.getElementById('errormsg-container');
                    if (!state.ismobile) {
                        // container
                        el1.style.width = '428px';
                        el1.style.height = '926px';
                        el1.style.margin = 'auto';

                        // errormsg
                        em.style.width = '321px';
                        em.style.marginLeft = '51px';
                        em.style.top = 'calc((' + (926 - em.offsetHeight) + 'px) / 2)';
                    } else {
                        em.style.top = (window.innerHeight - em.offsetHeight) / 2 + 'px';
                    }
                });
            }
            commit('SET_ERRORMSG', errormsg);
        }


    }
};