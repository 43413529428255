import axios from 'axios';
import store from '../store';
// import router from '../router';

export default {
    namespaced: true,
    state: {
        anontoken: null,
    },
    getters: {
        anontoken(state) {
            return state.anontoken;
        },
    },
    mutations: {
        SET_ANONTOKEN(state, anontoken) {
            state.anontoken = anontoken;
        },
    },
    actions: {
        async getToken({ commit }) {
            let response = await axios.get('anonauth/gettoken');
            if (response && response.data.autherrormsg) {
                store.dispatch('fillabledoc/setErrorMsg', response.data.autherrormsg);
                return false;
            } else if (response.data.anontoken) {
                store.dispatch('fillabledoc/setErrorMsg', null);
                commit('SET_ANONTOKEN', response.data.anontoken);

                if (response.data.unioninfo) {
                    store.commit('fillabledoc/SET_UNIONINFO', response.data.unioninfo);
                }
                return true;
            }
        },

        async attempt() {
            // headers with token set in subscriber.js
            let response = await axios.get('anonauth/me');
            if (response.data.errormsg) {
                store.commit('fillablepage/SET_PAGEERROR', 'Authentication problem. Try clearing cookies for this site, then attempt the provided link again.');
                return false;
            } else if (response.data.consent_declined) {
                store.commit('fillablepage/SET_PAGEMESSAGE', response.data.consent_declined);
                return false;
            } else if (response.data.anontoken) {
                store.dispatch('fillabledoc/setErrorMsg', null);

                if (response.data.document_viewed) {
                    store.commit('fillableclient/SET_SHOWVIEWDOWNLOAD', true);
                    store.commit('fillableclient/SET_DOCUMENTVIEWED', response.data.document_viewed);
                } else if (response.data.consent_granted) {
                    // console.log('capturing consent_granted');
                    store.commit('fillabledoc/SET_CONSENTGRANTED', response.data.consent_granted);
                    store.commit('fillableclient/SET_SHOWVIEWDOWNLOAD', false);
                    store.commit('fillableclient/SET_DOCUMENTVIEWED', false);
                } else if (response.data.email_verified) {
                    // console.log('capturing email_verified');
                    store.commit('fillabledoc/SET_EMAILADDRESSISVERIFIED', response.data.email_verified);
                    store.commit('fillabledoc/SET_CONSENTGRANTED', false);
                    store.commit('fillableclient/SET_SHOWVIEWDOWNLOAD', false);
                    store.commit('fillableclient/SET_DOCUMENTVIEWED', false);
                } else if (!response.data.email_verified) {
                    store.commit('fillabledoc/SET_EMAILADDRESSISVERIFIED', false);
                    store.commit('fillabledoc/SET_CONSENTGRANTED', false);
                    store.commit('fillableclient/SET_SHOWVIEWDOWNLOAD', false);
                    store.commit('fillableclient/SET_DOCUMENTVIEWED', false);
                }

                if (response.data.unioninfo) {
                    store.commit('fillabledoc/SET_UNIONINFO', response.data.unioninfo);
                }
                return true;
            }

        },

    }
};